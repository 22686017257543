.productCard {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .productImg {
    max-width: 100%;
    max-height: 200px;
    height: 200px;
    border-radius: 10px;
  }
  
  .productTitle {
    font-size: 1.25rem;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .productPrice {
    font-size: 1.5rem;
    color: #333;
  }
  
  .productReviews {
    font-size: 0.9rem;
    color: #777;
  }
  
  .stars {
    color: #ffd700;
  }
  
  .productDropdown {
    margin-top: 15px;
  }
  
  .addToCartBtn {
    background-color: green;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }
  
  .addToCartBtn:hover {
    background-color: rgb(76, 171, 76);
    color: white;
  }
  