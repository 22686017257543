body {
    font-family: Arial, sans-serif;
}

table {
    font-size: 0.9rem;
}

tfoot td {
    font-weight: bold;
}

.text-end {
    text-align: right;
}

table thead th {
    border-bottom: 2px solid #e9ecef;
}

small {
    color: #6c757d;
}

.table-responsive {
    margin-top: 20px;
}


.HoriScroll{
    background-color: red !important;
    border: 2px solid red !important;
}

.global{
color:red !important;
}


.HoriScroll::-webkit-scrollbar {
    height: 8px !important; /* Adjust the height of the scrollbar */
  }
  
  .HoriScroll::-webkit-scrollbar-thumb {
    background-color: #b0c4de !important; /* Light greyish-blue color */
    border-radius: 10px !important; /* Rounded corners for a sleek look */
  }
  
  .HoriScroll::-webkit-scrollbar-thumb:hover {
    background-color: #9aa8c1 !important; /* Slightly darker color on hover */
  }
  
  .HoriScroll::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important; /* Light background for the scroll track */
    border-radius: 10px !important; /* Rounded corners for the scroll track */
  }
