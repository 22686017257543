.downbar{
    margin-top: 7vh !important
}

.customZ{
    z-index: 999 !important;
}

 .customInput input[type=text] {
 
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color:rgb(240, 237, 237);
   min-width:200px;
    background-position: 10px 10px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    background-image: url('../../assets/images/logo443.jpeg');
   
  }
