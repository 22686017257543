/* Shopping Cart Styles */
.shoppingCart {
    background-color: #fff;
    max-width: 600px;
    margin: auto;
  }
  
  .alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cart-item img {
    max-width: 100px;
  }
  
  .cart-item .col-9 {
    display: flex;
    flex-direction: column;
  }
  
  .btnPink {
    background-color: #ff69b4;
    color: white;
    border: none;
  }
  
  .btnPink:hover {
    background-color: #ff4d88;
  }
  
  .btnClose {
    background-color: transparent;
    font-size: 1.5rem;
    border: none;
  }
  